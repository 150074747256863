import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import './InputHTML.scss';

/* Documentaiotn
https://quilljs.com/docs/api/
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useQuill } from 'react-quilljs';
import ImageResize from 'quill-image-resize-module-react';

const ToolbarDefault = [
    [{ header: [1, 2, 3, 4, 5, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean']
];

const InputHtmlToolbarExtended = [
    [{ header: [1, 2, 3, 4, 5, false] }, 'bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'clean']
];

const FormatsDefault = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background',
    'list', 'indent',
    'link', 'image'
];

const InputHtml = ({
    onChange,
    value = '',
    height,
    toolbar = ToolbarDefault,
    formats = FormatsDefault,
    theme = 'bubble',
    className = 'ant-input input-html',
    container = '#ds',
    disabled = false,
    placeholder,
}) => {
    const modules = {
        toolbar,
        clipboard: { matchVisual: false },
        /* imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        } */
    };
    const { quill, quillRef, Quill } = useQuill({
        theme,
        modules,
        formats,
        placeholder,
        bounds: container
    });

    const isEmpty = v => v.replace(/<(.|\n)*?>/g, '')
        .trim().length === 0;

    if (Quill && !quill) { // For execute this line only once.
        Quill.register('modules/imageResize', ImageResize);
    }

    React.useEffect(() => {
        if (quill) {
            if (onChange) {
                quill.on('selection-change', (range, oldRange) => {
                    if (range === null && oldRange !== null) {
                        const html = quill.root.innerHTML;
                        onChange(isEmpty(html) ? null : html);
                    }
                });
            }
        }
        return () => quill && quill.off('selection-change');
    }, [quill, onChange]);

    React.useEffect(() => {
        if (quill && quillRef.current) {
            const editor = quillRef.current.querySelector('.ql-editor');
            editor.innerHTML = value;
        }
    }, [quill, value, quillRef]);

    const thisClassName = `${className} ${disabled ? 'input-html-disabled' : ''}`;

    return (
        <div className={thisClassName} style={height ? { height } : {}}>
            <div ref={quillRef} />
        </div>
    );
};

InputHtml.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    formats: PropTypes.object,
    placeholder: PropTypes.string,
    height: PropTypes.string,
    toolbar: PropTypes.array,
    theme: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    container: PropTypes.string,

};

export { InputHtml, InputHtmlToolbarExtended };
